import React from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';

import WebChat from './WebChat';

import './fabric-icons-inline.css';
import './MinimizableWebChat.css';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.handleFetchToken = this.handleFetchToken.bind(this);
    this.handleMaximizeButtonClick = this.handleMaximizeButtonClick.bind(this);
    this.handleMinimizeButtonClick = this.handleMinimizeButtonClick.bind(this);
    this.handleSwitchButtonClick = this.handleSwitchButtonClick.bind(this);
    const locale = document.documentElement.lang || window.navigator.language;

    const store = createStore({}, ({ dispatch }) => next => action => {
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        dispatch({
          type: 'WEB_CHAT/SEND_EVENT',
          payload: {
            name: 'webchat/join',
            value: { language: locale }
          }
        });
      } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
        if (action.payload.activity.from.role === 'bot') {
          this.setState(() => ({ newMessage: true }));

        }
      } else if (action.type === 'DIRECT_LINE/POST_ACTIVITY_FULFILLED' 
      ) {        
        if (this.UploadButton === false) {
          this.setState(() => ({ UploadButton: true }));
        } else {
          this.setState(() => ({ UploadButton: false }));
        }
      }
      return next(action);
    });

    this.state = {
      locale: locale,
      minimized: true,
      newMessage: false,
      UploadButton: false,
      side: 'right',
      store,
      styleSet: createStyleSet({
        backgroundColor: 'Transparent',
      }),
      token: null
    };
  }

  async handleFetchToken() {
    if (!this.state.token) {
      const vtoken = (process.env.REACT_APP_TOKEN);
      const jToken = { 'token': vtoken };
      this.setState(() => (jToken));
    }
  }

  handleMaximizeButtonClick() {
    this.setState(() => ({
      minimized: false,
      newMessage: false
    }));
  }

  handleMinimizeButtonClick() {
    this.setState(() => ({
      minimized: true,
      newMessage: false
    }));
  }

  handleSwitchButtonClick() {
    this.setState(({ side }) => ({
      side: side === 'left' ? 'right' : 'left'
    }));
  }

  render() {
    const { state: {
      locale,
      minimized,
      newMessage,
      UploadButton,
      side,
      store,
      styleSet,
      token
    } } = this;

    return (
      <div className="minimizable-web-chat">
        {
          minimized ?
            <button
              className="maximize"
              onClick={this.handleMaximizeButtonClick}
            >
              <span className="ms-Icon ms-Icon--CircleShapeSolid green-dot" />
              {
                newMessage &&
                <span className="ms-Icon message-dot" />
              }
            </button>
            :
            <div
              className={side === 'left' ? 'chat-box left' : 'chat-box right'}
            >
              <header>
                <div className="filler" />
                <button
                  className="switch"
                  onClick={this.handleSwitchButtonClick}
                >
                  <span className="ms-Icon ms-Icon--Switch" />
                </button>
                <button
                  className="minimize"
                  onClick={this.handleMinimizeButtonClick}
                >
                  <span className="ms-Icon ms-Icon--ChromeMinimize" />
                </button>
                <span className="ms-Icon ms-Icon--CircleShapeSolid green-got-header " />
              </header>
              <WebChat
                className="react-web-chat"
                onFetchToken={this.handleFetchToken}
                store={store}
                styleSet={styleSet}
                token={token}
                locale={locale}
              />
            </div>
        }
      </div>

    );
  }
}