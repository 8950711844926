import memoize from 'memoize-one';
import React from 'react';
import CarolineImage from './Images/caroline-min.png';
import UserImage from './Images/user.png';
import ReactWebChat, {
    createStore,
    createDirectLine,
    createStyleSet,
    connectToWebChat
}
from 'botframework-webchat';
import PasswordInputActivity from "./loginCard";

import './WebChat.css';

// Get parameters from query
//const params = ReactWebChat.queryParams(location.search);

// Language definition - ici on pourrait forcer une locale si besoin
//var chatLocale = params['locale'] || window.navigator.language;
const DEFAULT_ACCENT = '#0063B1';
const DEFAULT_SUBTLE = '#767676'; // With contrast 4.5:1 to white
const PADDING_REGULAR = 10;

const ConnectedPasswordInputActivity = connectToWebChat(
        ({
            sendPostBack
        }) => ({
            sendPostBack
        }))(props => < PasswordInputActivity {
            ...props
        }
        /> )

        export default class extends React.Component {
            constructor(props) {
                super(props);

                this.createDirectLine = memoize(token => createDirectLine({
                    token
                }));
                this.UploadButton = true;
                //this.styleSet = { fontSize: '24px !important '};
                this.styleOptions = {
                    hideUploadButton: true,
                    // Color and paddings
                    accent: DEFAULT_ACCENT,
                    backgroundColor: '#F2F6F9',// '#e3eef7', //'White',
                    cardEmphasisBackgroundColor: '#F0F0F0',
                    paddingRegular: PADDING_REGULAR,
                    paddingWide: PADDING_REGULAR * 2,
                    subtle: DEFAULT_SUBTLE,
                    // Word break
                    messageActivityWordBreak: 'break-word', // 'normal' || 'break-all' || 'break-word' || 'keep-all'
                    // Fonts
                    fontSizeSmall: '80%',
                    monospaceFont: 'fontFamily([\'Consolas\', \'Courier New\', \'monospace\'])',
                    primaryFont: 'fontFamily([\'Calibri\', \'Helvetica Neue\', \'Arial\', \'sans-serif\'])',
                    // Avatar
                    avatarSize: 40,
                    botAvatarBackgroundColor: undefined, // defaults to accent color
                    botAvatarImage: CarolineImage,
                    botAvatarInitials: '',
                    userAvatarBackgroundColor: undefined, // defaults to accent color
                    userAvatarImage: UserImage,
                    userAvatarInitials: '',
                    
                    // Bubble
                    bubbleBackground: 'White',
                    bubbleFromUserBackground: 'White',
                    bubbleBorderColor: '#E6E6E6',
                    bubbleBorderRadius: 3,
                    bubbleBorderStyle: 'solid',
                    bubbleBorderWidth: 1,
                    bubbleFromUserBorderColor: '#E6E6E6',
                    bubbleFromUserBorderRadius: 3,
                    bubbleFromUserBorderStyle: 'solid',
                    bubbleFromUserBorderWidth: 1,
                    bubbleFromUserNubOffset: 'bottom',
                    bubbleFromUserNubSize: 0,
                    bubbleFromUserTextColor: 'Black',
                    bubbleImageHeight: 240,
                    bubbleMaxWidth: 480, // screen width = 600px
                    bubbleMinHeight: 40,
                    bubbleMinWidth: 250, // min screen width = 300px, Edge requires 372px (https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13621468/)
                    bubbleNubOffset: 'bottom',
                    bubbleNubSize: 0,
                    bubbleTextColor: 'Black',

                    // Markdown
                    markdownRespectCRLF: true,

                    // Rich Cards
                    richCardWrapTitle: false, // Applies to subtitles as well

                    // Root
                    rootHeight: '100%',
                    rootWidth: '100%',

                    // Scroll to end button
                    hideScrollToEndButton: false,
                    // Send box
                    hideSendBox: false,
                    hideUploadButton: true,
                    microphoneButtonColorOnDictate: '#F33',
                    sendBoxBackground: 'White',
                    sendBoxButtonColor: undefined, // defaults to subtle
                    sendBoxButtonColorOnDisabled: '#CCC',
                    sendBoxButtonColorOnFocus: '#333',
                    sendBoxButtonColorOnHover: '#333',
                    sendBoxDisabledTextColor: undefined, // defaults to subtle
                    sendBoxHeight: 40,
                    sendBoxMaxHeight: 200,
                    sendBoxTextColor: 'Black',
                    // TODO: We should deprecate this because there isn't an easy way to make the width of the send box, narrower than the transcript
                    sendBoxBorderBottom: '',
                    sendBoxBorderLeft: '',
                    sendBoxBorderRight: '',
                    sendBoxBorderTop: 'solid 1px #E6E6E6',
                    sendBoxPlaceholderColor: undefined, // defaults to subtle
                    sendBoxTextWrap: false,
                    
                    // Suggested actions (boutons)
                    suggestedActionBackground: 'White',
                    suggestedActionBorder: '3', // split into 3, null
                    suggestedActionBorderColor: '#03356B', //undefined, // defaults to accent
                    suggestedActionBorderStyle: 'solid', //Solid
                    suggestedActionBorderWidth: 1,
                    suggestedActionBorderRadius: 5,
                    suggestedActionImageHeight: 20,
                    suggestedActionTextColor: '#0063B1', //null,
                    suggestedActionDisabledBackground: undefined, // defaults to suggestedActionBackground
                    suggestedActionDisabledBorder: 'none',
                    suggestedActionDisabledBorderColor: '#0063B1', // '#E6E6E6',
                    suggestedActionDisabledBorderStyle: 'none', //Solid
                    suggestedActionDisabledBorderWidth: 2,
                    suggestedActionDisabledTextColor: undefined, // defaults to subtle
                    suggestedActionHeight: 25,
                    
                    // Transcript overlay buttons (e.g. carousel and suggested action flippers, scroll to bottom, etc.)
                    transcriptOverlayButtonBackground: 'rgba(0, 0, 0, .6)',
                    transcriptOverlayButtonBackgroundOnFocus: 'rgba(0, 0, 0, .8)',
                    transcriptOverlayButtonBackgroundOnHover: 'rgba(0, 0, 0, .8)',
                    transcriptOverlayButtonColor: 'White',
                    transcriptOverlayButtonColorOnFocus: undefined, // defaults to transcriptOverlayButtonColor
                    transcriptOverlayButtonColorOnHover: undefined, // defaults to transcriptOverlayButtonColor
                };
            }

            componentDidMount() {
                !this.props.token && this.props.onFetchToken();
            }

            attachmentMiddleware = () => next => card => {
                if (card.attachment.contentType === 'application/vnd.microsoft.card.custom') {
                    card.attachment.contentType = 'application/vnd.microsoft.card.adaptive'
                }
                return next(card)
            };

            activityMiddleware = () => next => card => {
                const {
                    activity: {
                        name,
                        type
                    }
                } = card;
                //console.log(name);
                if (name === 'passwordInput') {
                    return () => [ < ConnectedPasswordInputActivity / > ];
                } else if (name === 'EnableUploadButton') {
                    //console.log (this.styleOptions) ;
                    this.styleOptions.hideUploadButton = false;
                    this.UploadButton = false;
                    return next(card);
                } else if (name === 'DisableUploadButton') {
                    //console.log (this.styleOptions) ;
                    this.styleOptions.hideUploadButton = true;
                    this.UploadButton = true;
                    return next(card);
                } else {
                    return next(card);
                }
            };

            render() {
                const {
                    props: {
                        className,
                        store,
                        token
                    },
                    styleOptions: {
                        styleOptions
                    }
                } = this;

                return (
                    token ?
                    <
                    ReactWebChat activityMiddleware = {
                        this.activityMiddleware
                    }
                    attachmentMiddleware = {
                        this.attachmentMiddleware
                    }
                    className = {
                        `${ className || '' } web-chat`
                    }
                    directLine = {
                        this.createDirectLine(token)
                    }
                    store = {
                        store
                    }
                    styleOptions = {
                        this.styleOptions
                    }
                    locale = {
                        document.documentElement.lang || window.navigator.language                        
                    }
                    /> :
                    <
                    div className = {
                        `${ className || '' } connect-spinner`
                    } >
                    <
                    div className = "content" >
                    <
                    div className = "icon" >
                    <
                    span className = "ms-Icon ms-Icon--Robot" / >
                    <
                    / div > <
                    p > Please wait
                    while we are connecting. < / p > <
                    / div > <
                    / div > );
                }
            }