import { css } from 'glamor'
import React from 'react';

const PASSWORD_INPUT_CSS = css({
    backgroundColor: 'rgb(0, 191, 255)',
    borderRadius: 3,
    color: 'White',
    display: 'block',
    margin: 10,
    padding: 5,

    '& > label': {
      display: 'block',
      flex: 1,
      fontFamily: 'Calibri, \'Helvetica Neue\', Arial, sans-serif',

      '& > span': {
        padding: 10,
        content: "\A",
      },
      '& > button': {
        marginTop: "5px",
        outline: 0,
        padding: '4 10px',
        width: '95%',
      },

      '& > input': {
        border:10,
        borderRadius: 3,
        flex: 1,
        letterSpacing: '.0em',
        outline: 0,
        padding: '0 10px',
        width: '90%',

        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, .5)',
          color: 'White'
        }
      }
    }
  });

export default class PasswordInputActivity extends React.Component {
constructor(props) {
    super(props);

    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleCodeChangeUser = this.handleCodeChangeUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCodeChangeBtnLogin = this.handleCodeChangeBtnLogin.bind(this);
    this.handleCodeChangeBtnAnnuler = this.handleCodeChangeBtnAnnuler.bind(this);

    this.state = {
    username: '',
    pwd: '',
    submitted: false,
    btn:'',
    };
}

handleCodeChange({ target: { value: password } }) {
    this.setState(() => ({ password }));
}

handleCodeChangeUser({ target: { value: username } }) {
    this.setState(() => ({ username }));
}

handleCodeChangeBtnLogin({ target: { value: btn } }) {
    this.setState(() => ({ btn : "login" }));
}

handleCodeChangeBtnAnnuler({ target: { value: btn } }) {
    this.setState(() => ({ btn : "annuler" }));
}

handleSubmit(event) {
    event.preventDefault();

    this.props.sendPostBack({ username: this.state.username, pwd: this.state.password, btn: this.state.btn });
    this.setState(() => ({ submitted: true }));
}

render() {
    const { state: { username ,password, submitted ,btn} } = this;

    return (
    <form
        className={ PASSWORD_INPUT_CSS }
        onSubmit={ this.handleSubmit }>
        <label>
        <span>Nom d'utilisateur:</span>
        <input
            autoFocus={ true }
            disabled={ submitted }
            onChange={ this.handleCodeChangeUser }
            type="text"
            value={ username }
        />
        </label>
        <label>
        <span>Mot de passe:</span>
        <input
            autoFocus={ true }
            disabled={ submitted }
            onChange={ this.handleCodeChange }
            type="password"
            value={ password }
        />
        </label>
        <label>
            <button type="submit" value={ "login" } onClick={ this.handleCodeChangeBtnLogin } autoFocus={ true } disabled={ submitted }>Login </button>
            <button type="submit" value={ "annuler" } onClick={ this.handleCodeChangeBtnAnnuler } autoFocus={ true } disabled={ submitted }>Annuler </button>
        </label>
   </form>
    );
    }
}


